.full-screen-container {
  background-color: #ffffff;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1300;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.loader-container {
  max-width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#loader-stroke-logo {
  max-width: 300px;
  animation: fadeOut 1s 2.5s ease forwards;
}

#loader-logo {
  max-width: 300px;
  opacity: 0;
  animation: fadeIn 2s 2.5s ease forwards, pump 4s 5s ease-in-out infinite;
}

#loader-stroke-logo path:nth-child(1) {
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  animation: strokeAnimate 2s 0.5s ease forwards;
}

#loader-stroke-logo path:nth-child(2) {
  stroke-dasharray: 700;
  stroke-dashoffset: 700;
  animation: strokeAnimate 2s 0.8s ease forwards;
}

#loader-stroke-logo path:nth-child(3) {
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  animation: strokeAnimate 2s 1.4s ease forwards;
}

#loader-stroke-logo path:nth-child(4) {
  stroke-dasharray: 700;
  stroke-dashoffset: 700;
  animation: strokeAnimate 2s 1.7s ease forwards;
}

@keyframes strokeAnimate {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

@keyframes pump {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.05);
  }
  40% {
    transform: scale(1);
  }
}

@media screen and (max-width: 768px) {
  .loader-container {
    max-width: 250px;
  }
  #loader-stroke-logo {
    width: 250px;
  }
  #loader-logo {
    width: 250px;
  }
}

@media screen and (max-width: 650px) {
  .loader-container {
    max-width: 200px;
  }
  #loader-stroke-logo {
    width: 200px;
  }
  #loader-logo {
    width: 200px;
  }
}