.background {
  background-color: #F5F6FA;
}

.swipeable-tab-container {
  position: absolute;
  overflow: hidden;
  background-color: #F5F6FA;
  width: 100vw;
  height: calc(100% - 112px);
}

.swipeable-tab {
  overflow-y: auto;
  position: absolute;
  width: 100%;

  //hide scrollbar
  -ms-overflow-style: none;
  /* For Firefox */
  scrollbar-width: none;
  /* For Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.parking-footer-button{
  border-radius: 12px;
  height: 48px;
  background-color: rgba(62, 201, 142, 0.30);
  width: calc(70% + 34px);
  border: none;
  box-shadow: none;
}

.parking-footer-white {
  background-color: #FFF;
  border-radius: 1.25rem 1.25rem 0rem 0rem;
  margin-left: 0;
  margin-right: 0;
  bottom: -48px;
  position: absolute;
  z-index: 1;
  -ms-touch-action: none;
  touch-action: none;
  box-shadow: 0px 4px 16px 0px rgba(43, 68, 105, 0.20);
  width: 100%;
}

.swipe-arrows {
  position: absolute;
  bottom: 94px;
  opacity: 0;
  animation: rightArrowAnimate 5s ease-in-out infinite;
}

.text-overlay {
  position: relative;
  text-align: center;
}

@keyframes rightArrowAnimate {
  0% {
    transform: translateX(-50%);
  }
  25% {
    opacity: 0;
    transform: translateX(-50%);
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
    transform: translateX(50%);
  }
  100% {
    transform: translateX(50%);
  }
} 

@keyframes showHide {
  0% {width:100%}
  40% {width:0%}
  60% {width:0%;}
  100% {width:100%;}
}

.parking-history-card {
  cursor: pointer;
}

.parking-history-card:hover {
  box-shadow: 0px 2px 8px rgba(35, 49, 68, 0.35);
}

.history-card-address-text {
  color: rgba(69, 93, 128, 0.87);
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.history-card-date-text {
  color: rgba(69, 93, 128, 0.87);
  font-family: Roboto-Bold;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.prevent-select {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}