.bank-img-regular {
  align-items: center;
  display: flex;
}

.bank-img-regular img {
  max-height: 45px;
}

.bank-img-tiny {
  align-items: center;
  display: flex;
}

.bank-img-tiny img {
  max-height: 30px;
}