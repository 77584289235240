@font-face {
  font-family: 'Inter-UI-Regular';
  font-style: normal;
  font-weight: normal;
  src: URL('./fonts/Inter-UI-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Inter-UI-Italic';
  font-style: italic;
  font-weight: normal;
  src: URL('./fonts/Inter-UI-Italic.otf') format('opentype');
}
@font-face {
  font-family: 'Inter-UI-Bold';
  font-style: normal;
  font-weight: bold; 
  src: URL('./fonts/Inter-UI-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'AlrightSans-Bold';
  src: URL('./fonts/AlrightSans-Bold-v3.otf') format('opentype');
}
@font-face {
  font-family: 'BebasNeue-Regular';
  src: URL('./fonts/BebasNeue-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('./fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url('./fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Semi-Bold';
  src: url('./fonts/Roboto-Semi-Bold.ttf');
  font-weight: 500;
  font-style: normal;
}