@import 'src/assets/styles/variables';

.action-btn {
border: 1px solid $secondary !important;
  box-shadow: grey;
  background: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 5px !important;
  justify-content: center;
  padding: 0 !important;
  width: 25px;
  height: 25px;
  min-width: 60px !important;
  color: $secondary !important;

  .share-btn-text {
    color: $secondary;
    text-transform: none;
    font-family: 'Inter-UI-Regular';
    font-size: 0.75em;
  }
}

.input-multiline-container {
  width: 100%;
  margin: 1em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $borders;
  background-color: $surface;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.input-label {
  transform: translate(2px, -6px) scale(0.8125) !important;
  transform-origin: top left;
  z-index: 0;
}

.input-multiline-text {
  min-height: 40px;
  padding-left: 0.75em;
  color: $text-color;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $secondary;
  background-color: $surface;
  color: $text-color;
}

.input-multiline-text input {
  font-size: 16px !important;
}

.announcement-container {
  position: relative;
  top: 50px;
}

.announcement-banner {
  color: #ffffff;
  background: #3A4D8F;
  border: 1px solid #3A4D8F;
}

.switch-container {
  color: #27348A;
}

.translation-title-items {
  display: flex;
  align-items: center;
}

.warning-icon {
  color: #FF8B79;
  font-size: 100px;
}

.publish-dialog-text {
  font-family: Roboto-Bold;
  display: flex;
  text-align: center;
  font-size: 1rem;
  color: #FF8B79;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.0em;
}

.blue-btn {
  background: #3A4D8F;
  color: $surface;
  border-radius: 20px;
  padding: 3px;
}

.red-btn {
  background: #FF8B79 ;
  color: $surface;
  border-radius: 20px;
  padding: 3px;
}

.divider-full-width {
  width: 100vw;
}

.date-text {
  color: #ffffff;
}