.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;


}

.my-swipeable-drawer {
  .MuiBackdrop-root {
    backdrop-filter: blur(4px); /* For Chrome, Edge, and Opera */
    -webkit-backdrop-filter: blur(4px); /* For Safari 9+ and Safari on iOS 9+ */
    background: rgba(43, 68, 105, 0.40);
  }
}