@import '../../../../assets/styles/variables';

.list-head {
  height: 67px;
}
.plate-container {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 2px solid $secondary;
  border-radius: 5px;
  margin-right: 0.5em;
}
.vehicle-name {
  align-self: baseline;
}
.add-btn {
  border: 1px solid $borders !important;
  display: flex;
  background: $surface !important;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}