.local-h5 {
  font-weight: 500;
  font-size: 0.875rem;
  color: #27348A;
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
}

.bold-text {
  font-weight: 600;
}