.parking-plate-num {
  color: #3268B2;
  font-family: 'Roboto';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.parking-plate-num-inactive {
  color: #C1D2E8;
  font-family: 'Roboto';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.number-plate {
  border-radius: 0.5rem;
  border: 3px solid #3268B2;
  background: #3268B2;
  box-shadow: 0px 2px 4px 0px rgba(69, 93, 128, 0.25);
}

.number-plate-inactive {
  border-radius: 0.5rem;
  border: 3px solid #C1D2E8;
  background: #C1D2E8;
}

.input-number-plate {
  width: 160px;
  padding: 0px 5px;
  background: #FBFCFE;
  outline: none;
  text-align: center;
  border-radius: 0.6rem;
  border: 3px solid #C1D2E8;
  background: #FBFCFE;
  color: #3268B2;
  font-family: 'Roboto';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}