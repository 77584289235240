.content {
    min-height: calc(100vh - 65px);
    background-color: #f5f6fa;
    position: 'relative';
    overflow: 'auto';
    padding: 1px 0px;
  }
  
  .img-flag {
    width: 27px;
    height: 20px;
    border-radius: 5px;
  }
  
  .select-country {
    background: #F5F7FB;
    border-radius: 0.5rem;
    min-width: 160px;
    padding: 5px 15px 3px 10px;
  }
  
  .bank-button {
    border-radius: 12px;
    height: 48px;
    background-color: white;
    box-shadow: 0px 2px 8px 0px rgba(78, 108, 203, 0.25);
    width: 100%;
    text-transform: none;
  
    &:active, &:hover {
      box-shadow: 0;
      border: 2px solid #90ADD5;
    }
  }

  .bank-button-selected {
    border-radius: 12px;
    height: 48px;
    background-color: white;
    width: 100%;
    text-transform: none;
    border: 2px solid #90ADD5;
  }