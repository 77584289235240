.authInput{
    border-radius: 12px;
    height: 48px;
}

.authInput input {
    background-color: #F5F6FA;
    font-size: 16px !important;
}

.authInput fieldset {
    border-color: #F5F6FA !important;
}

.authInput div {
    background-color: #F5F6FA;
}

.auth-button {
    border-radius: 12px;
    height: 48px;
    background-color: #3268B2;
    box-shadow: 0px 2px 8px 0px rgba(78, 108, 203, 0.25);
    width: 100%;
    text-transform: none;
}

.button-primary-outlined-blue {
    text-transform: none;
    border-radius: 12px;
    width: 100%;
    border: 1px solid #3268B2;
    background-color: #FFF;
    color: #3268B2;
    height: 48px;
    font-family: 'Roboto-Semi-Bold';
    font-weight: 500;
    font-size: 0.875rem;
}