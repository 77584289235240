.image-container {
  margin-right: 0.75rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: left;
  height: 96px;
  width: 96px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  margin: 0 2px;
  cursor: pointer;
  z-index: 1060;
}

.image-container::before {
  content: ''; /* Create a pseudo-element */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    0deg,
    #455d80 6.77%,
    rgba(69, 93, 128, 0.52) 44.27%,
    rgba(41, 84, 148, 0) 100%,
    rgba(69, 93, 128, 0) 100%
  );
  z-index: 1050;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.territory-text {
  padding: 8px;
  color: #fff;
  position: absolute;
  bottom: 0;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  z-index: 1050;
}

.carousel {
  position: relative;
  z-index: 1050;
}

/* .carousel:before {
  content: '';
  background: linear-gradient(90deg, #FFF 3%, rgba(255, 255, 255, 0.00) 6%), linear-gradient(270deg, #FFF 3%, rgba(255, 255, 255, 0.00) 6%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 1050;
  pointer-events: none;
} */