.input-account input {
    color: #2B4469;
    letter-spacing: 0.03rem;
    border-radius: 12px;
    background-color: #F5F6FA;
    font-size: 16px !important;
}

.input-account fieldset {
    border-color: #F5F6FA !important;
}

.input-account div {
    background-color: #F5F6FA;
}

.button-secondary {
    background-color: #F5F6FA;
    border-radius: 12px;
    color: #FF7D6B;
    letter-spacing: 1px;

    &:focus,
    &:active,
    &:hover {
        background-color: #F5F6FA;
        outline: none;
    }
}

.button-primary {
    border-radius: 12px;
    height: 48px;
    background-color: #3268B2;
    width: 100%;
    font-size: large;
    box-shadow: 0px 8px 16px 0px rgba(78, 108, 203, 0.25);

    &:focus,
    &:active,
    &:hover {
        background-color: #3268B2;
        outline: none;
        box-shadow: 0px 8px 16px 0px rgba(78, 108, 203, 0.25);
    }
}

.button-primary-disabled {
    border-radius: 12px;
    height: 48px;
    background-color: #aaa;
    width: 100%;
    font-size: large;

    &:focus,
    &:active,
    &:hover {
        border-radius: 12px;
        height: 48px;
        background-color: #aaa;
        width: 100%;
        font-size: large;
    }
}

.button-warning {
    border-radius: 12px;
    background-color: #FF7D6B;
    width: 100%;
    font-size: large;
    box-shadow: 0px 8px 16px 0px rgba(203, 78, 80, 0.25);

    &:focus,
    &:active,
    &:hover {
        background-color: #FF7D6B;
        outline: none;
    }
}

.button-text {
    text-transform: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.035rem;
}

.button-primary-outlined {
    text-transform: none;
    border-radius: 12px;
    width: 100%;
    border: 1px solid #3268B2;
    color: #2B4469;
    background-color: #fff;
    line-height: 1rem;
}

.button-primary-outlined-blue {
    text-transform: none;
    border-radius: 12px;
    width: 100%;
    border: 1px solid #3268B2;
    background-color: #FFF;
    color: #3268B2;
    height: 48px;
    font-family: 'Roboto-Semi-Bold';
    font-weight: 500;
    font-size: 0.875rem;
}

.button-warning-outlined {
    text-transform: none;
    border-radius: 12px;
    width: 100%;
    border: 1px solid #FF7D6B;
    color: #FF7D6B;
    background-color: rgba(255, 125, 107, 0.05);

    &:active,
    &:hover {
        background-color: #FF7D6B;
        font-weight: 200;
        color: white;
    }
}

.button-secondary-outlined {
    border-radius: 12px;
    background-color: white;
    width: 100%;
    color: rgba(35, 49, 68, 0.5);
    border: 1px solid rgba(35, 49, 68, 0.5);
    font-size: large;

    &:focus,
    &:active,
    &:hover {
        background-color: #F5F6FA;
        outline: none;
    }
}

.bottom-button {
    margin-left: 0;
    margin-right: 0;
    bottom: 0px;
    position: absolute;
    width: 100%;
}

.logout-footer-white {
    background-color: white;
    border-Top-Right-Radius: 25px;
    border-top-left-radius: 25px;
    margin-left: 0;
    margin-right: 0;
    max-width: none;
    bottom: 0px;
    position: absolute;
}

.grey-box {
    width: 100%;
    height: 400px;
    background-color: gray;
    background: linear-gradient(180deg, white 0%, gray 100%);
}

.parking-time-info {
    font-size: 50px !important;
    color: #3268B2;
    font-weight: 900 !important;
}

.MuiSlider-root {
    padding: 0 0 0 0 !important;
}

.header-text {
    font-weight: 500;
    letter-spacing: '0.045rem';
    color: '#2B4469';
}

.padding_0 {
    padding: 0 !important;
}

.sign-out-card {
    position: absolute;
    bottom: 4.5rem;
    width: calc(100vw - 1rem);
}

.fade-in {
    animation: fade-in 0.75s linear;
}

.slide-down {
    animation: slide-down 0.4s cubic-bezier(0,.5,.6,1);
}

.slide-fade-in-up {
    opacity: 0;
    animation: slide-fade-in-up 0.35s cubic-bezier(0,.7,.75,1) forwards;
}

.slide-fade-in-up:nth-child(1) {
    animation-delay: 0.1s;
}

.slide-fade-in-up:nth-child(2) {
    animation-delay: 0.18s;
}

.slide-fade-in-up:nth-child(3) {
    animation-delay: 0.26s;
}

.slide-fade-in-up:nth-child(4) {
    animation-delay: 0.32s;
}

@keyframes fade-in {
    0%, 50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@keyframes slide-down {
    0% {
        transform: translateY(calc(-100% - 100px));
    }
  
    100% {
        transform: translateY(0px);
    }
}
  
@keyframes slide-fade-in-up {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
  
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}